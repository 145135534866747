import { Injectable, ComponentRef, Type, ViewContainerRef } from '@angular/core';
import { ArrayUtils } from '@core/utils';

interface ModalStackItem {
    ref: ComponentRef<any>;
    onClose?: (params?: any) => void;
}

@Injectable({
    providedIn: 'root',
})
export class ModalService {
    private container: ViewContainerRef | null = null;
    private modalStack: ModalStackItem[] = [];

    // Set the container (this will be registered by our modal host)
    public setContainer(container: ViewContainerRef): void {
        this.container = container;
    }

    // Opens a modal by dynamically creating the component
    public open<T>(component: Type<T>, data?: Partial<T>, onClose?: (params?: any) => void): ComponentRef<T> {
        if (!this.container) throw new Error('Modal container is not set!');

        // Hide the current top modal (if any)
        if (ArrayUtils.isNotEmpty(this.modalStack)) {
            const topModal = this.modalStack[this.modalStack.length - 1];
            topModal.ref.location.nativeElement.style.display = 'none';
        }

        // Dynamically create the modal component in the container
        const componentRef = this.container.createComponent(component);
        if (data) {
            Object.assign(componentRef.instance, data);
        }
        this.modalStack.push({ ref: componentRef, onClose });
        return componentRef;
    }

    /**
     * Closes the top modal.
     * @param triggerCallback If true and an onClose callback is registered, the callback will be invoked.
     */
    public close(triggerCallback: boolean = false, callbackParams?: any): void {
        if (ArrayUtils.isEmpty(this.modalStack)) return;
        const modalItem = this.modalStack.pop();
        if (modalItem) {
            // Trigger the callback if requested
            if (triggerCallback && modalItem.onClose) {
                modalItem.onClose(callbackParams);
            }
            modalItem.ref.destroy();
        }

        // If there’s a previous modal, make it visible again.
        if (ArrayUtils.isNotEmpty(this.modalStack)) {
            const previousModal = this.modalStack[this.modalStack.length - 1];
            previousModal.ref.location.nativeElement.style.display = '';
        }
    }
}
